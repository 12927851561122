import { Controller } from '@hotwired/stimulus'
import consumer from '../channels/consumer'

export default class extends Controller {

  static targets = [
    'template',
    'message',
    'toast'
  ]

  static values = {
    channel: String,
    guid: String,
    duration: { type: Number, default: 4000 }
  }

  initialize() {
    this.notification = this.notification.bind(this)
    document.addEventListener('turbo:before-fetch-response', this.notification)
    if (this.hasGuidValue) {
      this.initializeActionCable()
    }
  }

  notification(event) {
    const flashHeader = event.detail.fetchResponse.header('X-Flash-Messages')
    if (!flashHeader) return
    const flashObject = JSON.parse(flashHeader)
    const entries = Object.entries(flashObject).map(([type, subject]) => ({ type, subject }))
    this.notify(entries)
  }

  notify(entries) {
    if (!this.timeout) {
      this.element.classList.remove('hidden', 'opacity-0')
      this.element.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML)
    } 
    for (const entry of entries) {
      const { type, subject } = entry
      const content = document.createTextNode(subject)
      const messageElement = document.createElement('div')
      if (type == 'error') {
        messageElement.classList.add('text-red-500', 'dark:text-red-400')
      } else if (type == 'success') {
        //messageElement.classList.add('text-primary-500', 'dark:text-primary-400')
      }
      messageElement.appendChild(content)
      this.messageTarget.appendChild(messageElement)
    }
    if (this.timeout) {
      clearInterval(this.timeout)
    }
    this.timeout = setTimeout(() => this.hide(), this.durationValue)
  }

  hide() {
    this.element.classList.add('hidden', 'opacity-0')
    this.element.removeChild(this.element.lastElementChild)
    if (this.timeout) {
      clearInterval(this.timeout)
      this.timeout = undefined
    }
  }

  disconnect() {
    document.removeEventListener('turbo:before-fetch-response', this.notification)
    if (this.timeout) {
      clearInterval(this.timeout)
      this.timeout = undefined
    }
  }

  initializeActionCable() {
    consumer.subscriptions.create(
      {
        channel: this.channelValue,
        guid: this.guidValue
      },
      {
        received: this.cableReceived.bind(this)
      }
    )
  }

  cableReceived(payload) {
    this.notify([payload])
  }

}