class CountdownComponent {

  constructor(delta) {
    this.delta = Math.max(0, delta)
  }

  get value() { 
    const components = []
    const { days, hours, minutes, seconds } = this.elements
    if (days > 0) {
      components.push(`${days}d`)
      components.push(`${hours}h`)
    } else if (hours > 0) {
      components.push(`${hours}h`)
      if (minutes > 0) {
        components.push(`${minutes}m`)
      } else {
        components.push(`${seconds}s`)
      }
    } else if (minutes > 0) {
      components.push(`${minutes}m`)
      components.push(`${seconds}s`)
    } else {
      components.push(`${seconds}s`)
    }
    return components.join(' ')
  }

  get elements() {
    return {
      days: Math.floor(this.delta / (60 * 60 * 24 * 1000)),
      hours: Math.floor((this.delta / (60 * 60 * 1000)) % 24),
      minutes: Math.floor((this.delta / 60) % (60 * 1000) / 1000),
      seconds: Math.floor(this.delta % (60 * 1000) / 1000)
    }
  }

}

export default CountdownComponent