import { Controller } from '@hotwired/stimulus'
import 'flowbite'

export default class extends Controller {

  static targets = [
    'drawer'
  ]

  connect() {
    const options = {
      placement: 'right',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      bodyScrolling: false
    }
    this.drawer = new Drawer(this.drawerTarget, options)
    this.drawerTarget.classList.remove('hidden')
    this.uncacheDrawer = this.uncacheDrawer.bind(this)
    document.addEventListener('turbo:before-cache', this.uncacheDrawer)
  }

  disconnect() {
    document.removeEventListener('turbo:before-cache', this.uncacheDrawer)
  }

  show() {
    this.drawer.show()
  }

  hide() {
    this.drawer.hide()
  }

  async uncacheDrawer(_event) {
    //console.log('x')
    //const backdrops = [].slice.call(document.querySelectorAll('[drawer-backdrop]'))
    //backdrops.map(element => element.remove())
    this.hide()
    //this.drawerTarget.classList.remove('transform-none')
    //this.drawerTarget.classList.add('translate-x-full')
  }

}