if (window.GA4) {
  const googleAnalytics = function(event) {
    if (event.type == 'turbo:before-fetch-request') {
      if (event.detail.fetchOptions.headers['Turbo-Frame'] == 'dialog-frame') {
        const url = event.detail.url.href
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', window.GA4, { page_location: url });
      }
    } else if (event.type == 'turbo:load') {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', window.GA4);
    }
  }
  document.addEventListener('turbo:load', googleAnalytics)
  document.addEventListener('turbo:before-fetch-request', googleAnalytics)
}


