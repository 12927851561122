import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {

  static targets = [
    'buttonText'
  ]
  static values = {
    url: String
  }

  async copy() {
    this.element.disabled = true
    this.buttonTextTarget.classList.add('hidden')
    const response = await post(this.urlValue, {
      contentType: 'text/plain'
    })
    if (response.ok) {
      const text = await response.text
      navigator.clipboard.writeText(text)
    }
    this.element.disabled = false
    this.buttonTextTarget.classList.remove('hidden')
  }

}