import { Controller } from '@hotwired/stimulus'
import consumer from '../channels/consumer'

export default class extends Controller {

  connect() {
    this.initializeActionCable()
  }

  disconnect() {
  }

  broadcast(eventName, payload) {
    const event = new CustomEvent(eventName, {
      detail: payload
    })
    window.dispatchEvent(event)
  }

  initializeActionCable() {
    consumer.subscriptions.create(
      {
        channel: 'StateChannel'
      },
      {
        initialized: this.cableInitialized.bind(this),
        connected: this.cableConnected.bind(this),
        disconnected: this.cableDisconnected.bind(this),
        received: this.cableReceived.bind(this)
      }
    )
  }

  cableInitialized() {
    if (DEBUG) console.log('Cable initialized')
  }

  cableConnected() {
    if (DEBUG) console.log('Cable connected')
  }

  cableDisconnected() {
    if (DEBUG) console.log('Cable disconnect')
  }

  cableReceived(payload) {
    if (payload.lots) {
      this.broadcast('app:lots-state-event', payload.lots)
    }
    if (payload.events) {
      this.broadcast('app:events-state-event', payload.events)
    }
  }

}