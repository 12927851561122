import { Controller } from '@hotwired/stimulus'
import 'flowbite'

export default class extends Controller {

  connect() {
    const items = []
    this.element.querySelectorAll('[data-accordion-target]').forEach(el => {
      const item = {
        id: el.getAttribute('data-accordion-target'),
        triggerEl: el,
        targetEl: document.querySelector(el.getAttribute('data-accordion-target')),
        icon: el.querySelector('[data-accordion-icon]'),
        active: el.getAttribute('aria-expanded') === 'true' ? true : false
      }
      items.push(item)
    })    
    this.accordion = new Accordion(items, {
      alwaysOpen: false,
      activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
      inactiveClasses: 'text-gray-500 dark:text-gray-400'
    })
  }

  disconnect() {
    this.accordion = null
  }

}