import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'
import { from, concatMap } from 'rxjs' 

export default class extends Controller {

  static targets = [
    'input',
    'progress'
  ]
  
  static values = {
    endpoint: String,
    permitted: {
      type: Array, 
      default: []
    }
  }

  connect() { 
    this.progressTarget.style.width = 0
    this.addEventHandlers()
  }

  disconnect() {
    this.removeEventHandlers()
  }

  preventDefaults(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDrop(event) {
    const dataTransfer = event.dataTransfer
    const { files } = dataTransfer
    this.upload(files)
  }

  async upload(items) {
    const files = Array.from(items)
      .filter(item => this.permittedValue
        .filter(ext => item.name.toLowerCase().endsWith(ext)).length > 0
      )
    const count = files.length
    let completed = 0
    from(files)
      .pipe(
        concatMap(file => {
          return this.attach(file)
        })
      )
      .subscribe(
        response => {
          if (response.ok) {

          }
          const percentage = Math.round((++completed / count) * 100)
          this.progressTarget.style.width = `${percentage}%`
        }
      )
    this.inputTarget.value = null
  }

  async change(_event) {
    await this.upload(this.inputTarget.files)
  }
  
  attach(file) {
    let data = new FormData()
    data.append('attachment', file)
    return post(this.endpointValue, {
      body: data,
      headers: {
        accept: 'text/vnd.turbo-stream.html; charset=utf-8'
      }
    })
  }

  addEventHandlers() {
    this.preventDefaults = this.preventDefaults.bind(this)
    this.handleDrop = this.handleDrop.bind(this);
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.element.addEventListener(eventName, this.preventDefaults, false)
    })
    this.element.addEventListener('drop', this.handleDrop, false)
  }

  removeEventHandlers() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {  
      this.element.removeEventListener(eventName, this.preventDefaults, false)
    })
    this.element.removeEventListener('drop', this.handleDrop, false)
  }

}
