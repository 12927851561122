import { Controller } from '@hotwired/stimulus'
import 'flowbite'

export default class extends Controller {
  static targets = [
    'menu',
    'trigger'
  ]

  connect() {
    const options = {
      triggerType: 'hover',
      onHide: () => this.onHide()
    }
    this.dropdown = new Dropdown(
      this.menuTarget,
      this.triggerTarget, 
      options
    )
  }

  onHide() {}

  disconnect() {}
  
}