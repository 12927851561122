import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  
  static values = {
    url: String
  }
  
  static targets = [
    'text',
    'html'
  ]

  initialize() {
    this.load = debounce(() => this.loadHtml(), 1000).bind(this)
  }

  async loadHtml() {
    if ((this.textTarget.value || '').trim().length === 0) return
    const response = await post(this.urlValue, {
      body: {
        text: this.textTarget.value
      }
    })
    if (response.ok) {
      const html = await(response.html)
      this.htmlTarget.innerHTML = html
    }
  }

}

const debounce = (fn, delay = 1000) => {
  let timeoutId = null
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(fn, delay)
  }
}
