import { Controller } from '@hotwired/stimulus'
import lightGallery from 'lightgallery'
import lgZoom from 'lightgallery/plugins/zoom'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgVideo from 'lightgallery/plugins/video'


export default class extends Controller {

  static values = {
    options: Object
  }

  connect () {
    this.lightGallery = lightGallery(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.lightGallery.destroy()
  }

  get defaultOptions () {
    return {
      download: false,
      licenseKey: '000-0000-000-0001',
      showZoomInOutIcons: true,
      thumbnail: true,
      actualSize: false,
      plugins: [
        lgZoom,
        lgThumbnail,
        lgVideo
      ]
    }
  }

}
