import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  
  static values = {
    mode: String
  }
  static targets = ['schedule']

  connect() {
    this.toggle(this.modeValue)
  }

  onClick(event) {
    this.toggle(event.target.value)
  }

  toggle(mode) {
    if (['auction', 'advert'].indexOf(mode) >= 0) {
      this.scheduleTarget.classList.remove('hidden')
    } else {
      this.scheduleTarget.classList.add('hidden')
    }
  }

}