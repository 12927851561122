import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  hide(event) {
    if (event.detail.fetchResponse.response.ok) {
      document.dispatchEvent(new CustomEvent('CustomDialogClose'))
    }
  }

}