import { Controller } from '@hotwired/stimulus'
import 'flowbite'

export default class extends Controller {

  connect() {
    this.hide = this.hide.bind(this)
    document.addEventListener('CustomDialogClose', this.hide)
    const options = {
      onHide: () => this.onHide(),
      onShow: () => this.onShow(),
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 backdrop-blur-sm',
    }
    this.modal = new Modal(this.element, options)
    this.modal.show()
  }

  hide() {
    this.modal.hide()
  }

  onShow() {
  }
  
  onHide() {
    const dialogFrame = document.getElementById('dialog-frame')
    dialogFrame.removeAttribute('src')
    this.element.remove()  
  }

  disconnect() {
    document.removeEventListener('CustomModalClose', this.hide)
  }

}