import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  
  static values = {
    initial: String
  }
  
  static targets = [
    'business'
  ]

  initialize() {
    this.toggle(this.initialValue)
  }

  change(event) {
    const value = event.target.value
    this.toggle(value)
  }

  toggle(value) {
    if (value === 'business') {
      this.businessTarget.classList.remove('hidden')
    } else {
      this.businessTarget.classList.add('hidden')
    }
  }

}
