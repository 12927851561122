import { Controller } from '@hotwired/stimulus'
import urlB64ToUint8Array from '../support/base_64_to_bytes'
import { post } from '@rails/request.js'

const SESSION_KEY = 'notifySkip'
const DELAY = 120 * 1000

export default class extends Controller {

  static values = {
    vapidPublicKey: String,
    url: String
  }

  async connect() {
    this.show = this.show.bind(this)
    if (this.skipped || !this.hasVapidPublicKeyValue || !this.hasUrlValue) return
    const subscription = await this.getSubscription()
    if (subscription) {
      this.skipped = Date.now()
    } else {
      this.delayedPrompt()
    }
  }
  delayedPrompt() {
    const navigationStart = window.performance.timeOrigin
    const timeNow = Date.now()
    const delay = Math.max(DELAY - (timeNow - navigationStart), 0)
    setTimeout(this.show, delay)
  }

  show() {
    this.element.classList.remove('-translate-y-full', 'hidden')
    this.element.classList.add('transform-none')
    this.skipped = Date.now()
  }

  hide() {
    this.element.classList.add('-translate-y-full', 'hidden')
    this.element.classList.remove('transform-none')
    this.skipped = Date.now()
  }

  get skipped() {
    return sessionStorage.getItem(SESSION_KEY) !== null 
  }

  set skipped(value) {
    sessionStorage.setItem(SESSION_KEY, value)
  }

  async subscribe() {
    try {
      const serviceWorker = await navigator.serviceWorker.ready
      const options = {
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(this.vapidPublicKeyValue)
      }
      const subscription = await serviceWorker.pushManager.subscribe(options)
      await this.save(subscription)
    } catch(error) {
      console.log(error)
    } finally {
      this.hide()
    }
  }

  async save(subscription) {
    await post(this.urlValue, {
      body: JSON.stringify(subscription),
      responseKind: 'json'
    })
  }

  async getSubscription() {
    try {
      const serviceWorker = await navigator.serviceWorker.ready
      return await serviceWorker.pushManager.getSubscription()
    } catch(error) {
      console.log(reportError)
    }
  }

}