import { Controller } from '@hotwired/stimulus'
import CountdownComponent from '../support/countdown_component'

export default class extends Controller {

  static values = {
    state: String,
    id: Number,
    currentTime: Number,
    closeTime: Number,
    eventName: String,
    options: Object
  }

  connect() {
    if (this.isRunning) {
      this.renderCountdown()
      this.addServerTimeListener()
    } 
    this.addStateReceiveListener()
  }

  renderCountdown() {
    const countdownComponent = new CountdownComponent(this.delta)
    this.element.textContent = countdownComponent.value
  }

  renderDecoration() {
    this.element.classList = this.optionsValue.decorators[this.stateValue]
  }

  renderState() {
    this.element.textContent = this.stateValue
  }

  serverTime(event) {
    this.currentTimeValue = event.detail.currentTime
    if (this.delta > 0) {
      this.renderCountdown()
    }
  }

  get delta() {
    return this.closeTimeValue - this.currentTimeValue
  }

  get isRunning() {
    return ['running', 'extended'].includes(this.stateValue)
  }

  get isClosed() {
    return ['closed'].includes(this.stateValue)
  }

  stateReceive(event) {
    const found = (event.detail || []).find(entry => entry.id == this.idValue)
    if (found) {
      const { state, close } = found
      this.stateValue = state
      this.closeTimeValue = close
      if (this.isRunning) {
        this.renderCountdown()
        this.renderDecoration()
        this.addServerTimeListener()
      } else if (this.isClosed) {
        this.renderState()
        this.renderDecoration()
        this.removeListeners()
      } else {
        this.renderState()
        this.renderDecoration()
      }
    }
  }

  addServerTimeListener() {
    if (!this.boundServerTime) {
      this.boundServerTime = this.serverTime.bind(this)
      window.addEventListener('app:sync-time', this.boundServerTime)
    }
  }

  addStateReceiveListener() {
    if (!this.boundStateReceive) {
      this.boundStateReceive = this.stateReceive.bind(this)
      window.addEventListener(this.eventNameValue, this.boundStateReceive)
    }
  }
  
  removeListeners() {
    if (this.boundServerTime) {
      window.removeEventListener('app:sync-time', this.boundServerTime)
    }
    if (this.boundStateReceive) {
      window.removeEventListener(this.eventNameValue, this.boundStateReceive)
    }
  }

  disconnect() {
    this.removeListeners()
  }

}
