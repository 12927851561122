import { Controller } from '@hotwired/stimulus'
import consumer from '../channels/consumer'

export default class extends Controller {

  static values = { 
    currentTime: Number
  }

  connect() {
    this.interval = 1000
    this.expected = this.currentTimeValue
    this.getServerTime()
    this.initializeActionCable()
    this.update()
  }

  disconnect() {
    this.stopTimer()
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  update() {
    this.notify(this.currentTimeValue)
    const now = Date.now()
    const drift = now - this.expected
    //if (DEBUG) console.log(now, this.currentTimeValue, now - this.currentTimeValue, drift)
    this.expected += this.interval
    const max = Math.max(0, this.interval - drift)
    this.stopTimer()
    this.currentTimeValue += this.interval
    this.timer = setInterval(() => this.update(), max)
  }

  getServerTime() {
    fetch('/sync')
      .then(response => {
        const utc = parseInt(response.headers.get('x-sync-time'))
        this.currentTimeValue = utc
        this.expected = this.currentTimeValue
        this.stopTimer()
        this.update()
      })
      .catch(err => console.log(err))
  }

  notify(utc) {
    const event = new CustomEvent('app:sync-time', {
      detail: {
        currentTime: utc
      }
    })
    window.dispatchEvent(event)
  }

  initializeActionCable() {
    consumer.subscriptions.create(
      {
        channel: 'TimeChannel'
      },
      {
        initialized: this.cableInitialized.bind(this),
        connected: this.cableConnected.bind(this),
        disconnected: this.cableDisconnected.bind(this),
        received: this.cableReceived.bind(this)
      }
    )
  }

  cableInitialized() {
    if (DEBUG) console.log('Cable initialized')
  }

  cableConnected() {
    if (DEBUG) console.log('Cable connected')
  }

  cableDisconnected() {
    if (DEBUG) console.log('Cable disconnect')
  }

  cableReceived(data) {
    if (DEBUG) console.log(`Cable received utc ${data.utc}`)
    this.currentTimeValue = data.utc
    this.expected = this.currentTimeValue
    this.stopTimer()
    this.update()
  }

}